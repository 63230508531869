import { ClientGroupBriefDto, ClientGroupsClient } from "@api";
import { Popover, Typography } from "antd";
import { useLocation } from "@reach/router";
import { Space } from "lib";

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ClientGroupCascaderFilter from "../ClientGroupCascaderFilter";
import ClientGroupLogo from "../Clients/ClientGroupLogo";

type HeaderProps = {
  id: number;
};

const Header = ({ id }: HeaderProps): JSX.Element => {
  const location = useLocation();

  const clientGroupsClient = new ClientGroupsClient();
  const [state, setState] = useState({} as ClientGroupBriefDto);
  const [loading, setLoading] = useState(true);
  const [hasLogo, setHasLogo] = useState(false);

  async function fetchItem() {
    const data = await clientGroupsClient.getClientGroup(id);

    setState(data);
    setHasLogo(!!data.logoFileId);
    setLoading(false);
  }

  useEffect(() => {
    fetchItem();
  }, []);
  return loading ? (
    <></>
  ) : (
    <Space
      direction="vertical"
      style={{ marginLeft: "30px", marginTop: "30px" }}
    >
      <Space justify="flex-start">
        <Popover
          content={
            hasLogo ? (
              <ClientGroupLogo
                id={hasLogo ? id : undefined}
                size={200}
                shape="square"
                style={hasLogo ? { backgroundColor: "transparent" } : {}}
                gap={0}
              />
            ) : (
              <Typography.Text>
                <FormattedMessage id="StaticNaLabel" />
              </Typography.Text>
            )
          }
        >
          <ClientGroupLogo id={hasLogo ? id : undefined} spinSize="small" />
        </Popover>
        {state.name}
      </Space>
      <ClientGroupCascaderFilter
        placeholderId="ClientGroupClientGroupContentJumpToLabel"
        allowClear={false}
        handleAction={(id) => {
          const separator = "/";
          const temp = location.pathname.split(separator);
          console.log(temp);
          if (temp.length >= 4) {
            temp[3] = id.toString();

            window.location.replace(temp.join(separator));
          }
        }}
      />
    </Space>
  );
};

export default Header;
