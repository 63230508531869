import React, { useEffect, useState } from "react";
import {
  ClientUserBriefDto,
  ClientUsersClient,
  UpdateClientUserClientGroupCommand,
  BulkUpdateClientUserClientGroupCommand,
} from "@api";
import { ACTION, SubpageAuth } from "@services/auth";
import {
  Space,
  Table,
  TablePaginationConfig,
  Button,
  Modal,
  message,
} from "antd";
import { Key, TableRowSelection } from "antd/lib/table/interface";
import { FormattedMessage } from "react-intl";
import { showError } from "@action";
import ClientGroupCascaderFilter from "../ClientGroupCascaderFilter";

type HeaderProps = {
  keywords: string | undefined | null;
  groupId: number | undefined;
  columns: any;
  auth: SubpageAuth;
};

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const ClientUserMigrationTable = ({
  keywords,
  groupId,
  columns,
  auth,
}: HeaderProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();

  const clientUsersClient = new ClientUsersClient();

  const [state, setState] = useState({
    items: [] as ClientUserBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);

  const onSelectChange = (
    selectedRowKeys: Key[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedRows: ClientUserBriefDto[]
  ) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<ClientUserBriefDto> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [filter, setFilter] = useState({
    destination: undefined as number | undefined,
  });

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "ClientUsers")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await clientUsersClient.getClientUsersWithPagination(
      _keywords ?? keywords,
      groupId,
      filter.destination,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );

    const temp =
      (res.items as ClientUserBriefDto[]) || ([] as ClientUserBriefDto[]);

    setState({
      ...state,
      items: temp,
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  function handleChangeIsDestination(value: number | undefined) {
    setFilter((prev) => ({ ...prev, destination: value }));
  }

  function handleMigrate(id: string) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmMigrationMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientUsersClient.changeClientGroup(
            new UpdateClientUserClientGroupCommand({
              id,
              clientGroupId: filter.destination,
            })
          );
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }
  
  function handleBulkMigrate() {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmMigrationMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientUsersClient.bulkChangeClientGroup(
            new BulkUpdateClientUserClientGroupCommand({
              ids: selectedRowKeys.map(String),
              clientGroupId: filter.destination,
            })
          );
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  const columnsForMigration = [
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupUserMigrationTableHeaderAction" />,
      render: (text: any, record: ClientUserBriefDto) => (
        <Space>
          <Button
            disabled={!filter.destination}
            type="primary"
            onClick={() => handleMigrate(record.id as string)}
          >
            <Space>
              <FormattedMessage id="ClientUserClientUserTableMigrationButton" />
            </Space>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, [keywords, filter]);

  return (
    <>
      <Space>
        <ClientGroupCascaderFilter
          groupId={groupId}
          underGroupClient={
            !auth.can(ACTION.Read, "MigrateCascaderAllowDiffClient")
          }
          allowSelectFirstColumn={false}
          handleAction={handleChangeIsDestination}
        />
        
        <Button
            disabled={!filter.destination}
            type="primary"
            onClick={() => handleBulkMigrate()}
          >
            <Space>
              <FormattedMessage id="ClientUserClientUserTableMigrationButton" />
            </Space>
          </Button>
      </Space>
      <Table
        scroll={{ x: "max-content" }}
        bordered
        rowSelection={{
          type: "checkbox",
          preserveSelectedRowKeys: true,
          ...rowSelection,
        }}
        dataSource={[...state.items]}
        columns={columns.concat(columnsForMigration)}
        rowKey={"id"}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
      />
      {modalContextHolder}
      {messageContextHolder}
    </>
  );
};

export default ClientUserMigrationTable;
