import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const ClientGroupDetailMenu: React.FC<{ id: number }> = ({ id }) => (
  <MenuTabs
    items={[
      {
        id: "ClientGroupGroupSettingNavigationTitle",
        path: "/admin/clientgroup/" + id + "/groupsetting",
        page: "ClientGroups",
        subpage: "RegistrationPeriod",
      },
      {
        id: "ClientGroupTemplateNavigationTitle",
        path: "/admin/clientgroup/" + id + "/assessmenttemplate",
        page: "ClientGroups",
        subpage: "RegistrationPeriod",
      },
      {
        id: "ClientGroupClientAdminNavigationTitle",
        path: "/admin/clientgroup/" + id + "/groupadmin",
        page: "ClientGroups",
        subpage: "Assessment",
      },
      {
        id: "ClientGroupClientUserNavigationTitle",
        path: "/admin/clientgroup/" + id + "/clientuser",
        page: "ClientGroups",
        subpage: "Assessment",
      },
      {
        id: "ClientGroupAssessmentNavigationTitle",
        path: "/admin/clientgroup/" + id + "/assessment",
        page: "ClientGroups",
        subpage: "Assessment",
      },
      {
        id: "ClientGroupRegistrationPeriodNavigationTitle",
        path: "/admin/clientgroup/" + id + "/registrationperiod",
        page: "ClientGroups",
        subpage: "RegistrationPeriod",
      },
      {
        id: "ClientGroupEmailCustomizationNavigationTitle",
        path: "/admin/clientgroup/" + id + "/emailcustomization",
        page: "ClientGroups",
        subpage: "EmailCustomization",
      },
    ]}
  />
);

export default ClientGroupDetailMenu;
