import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Select } from "antd";
import { SupportedLanguageClient } from "@api";
import { showError } from "@action";
import { FormattedMessage } from "react-intl";

const Option = Select.Option;

interface IProps {
  handleAction: (
    targetOption: string | undefined
  ) => void | Dispatch<SetStateAction<string | null | undefined>>;
  isEnabled?: boolean;
  allowClear?: boolean;
}

const LanguageSelectFilter = ({
  handleAction,
  isEnabled,
  allowClear,
  ...rest
}: IProps) => {
  const [locales, setLocales] = useState([] as string[]);

  const handleChange = (value: string) => {
    handleAction(value);
  };

  useEffect(() => {
    const getSupportedLanguages = async () => {
      const langClient = new SupportedLanguageClient();
      const result = await langClient
        .getSupportedLanguages(isEnabled)
        .catch((err) => {
          showError(err);
          return [];
        });

      const temp = result.map((value) => value.id as string);
      setLocales(temp);
    };

    getSupportedLanguages();
  }, []);

  return (
    <Select
      style={{ width: 200 }}
      onChange={handleChange}
      allowClear={allowClear}
      placeholder={
        <FormattedMessage id={"LanguageLanguageStaticSelectLabel"} />
      }
      {...rest}
    >
      {locales.map((l) => (
        <Option key={l} value={l}>
          <FormattedMessage id={l} />
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelectFilter;

LanguageSelectFilter.defaultProps = {
  allowClear: true,
};
