import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Switch,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import {
  ClientUsersClient,
  ClientUserBriefDto,
  SendResetPasswordEmailCommand,
  ForceUpdateClientUserPasswordCommand,
} from "@api";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import Header from "@components/Admin/ClientGroup/Header";
import ClientGroupDetailMenu from "@components/Admin/ClientGroup/ClientGroupDetailMenu";
import { RcFile } from "antd/lib/upload";
import { PlusOutlined } from "@ant-design/icons";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import ResetPasswordModal from "@components/Admin/ResetPasswordModal";
import ClientUserMigrationTable from "@components/Admin/ClientGroup/ClientUserMigrationTable";
import LanguageSelectFilter from "@components/Admin/LanguageSelectFilter";

interface Params {
  id: number;
}
interface Props {
  params: Params;
}

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

enum Mode {
  Main = 0,
  Migration = 1,
}

const GroupLevelClientUserPage = ({ params }: Props) => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "ClientUsers");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }
  const groupId = params.id;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [createUserForm] = Form.useForm();
  const [editUserForm] = Form.useForm();

  const clientUsersClient = new ClientUsersClient();
  const [mode, setMode] = useState(Mode.Main);

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState(
    undefined as ClientUserBriefDto | undefined
  );

  const [state, setState] = useState({
    items: [] as ClientUserBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "ClientUsers")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await clientUsersClient.getClientUsersWithPagination(
      _keywords ?? keywords,
      groupId,
      undefined,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );
    setState({
      ...state,
      items: res.items ?? [],
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadClientUsers() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await clientUsersClient.export(groupId);
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadClientUsers(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await clientUsersClient.upload({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function OpenModalAddClientUser() {
    setCreateModalVisible(true);
    createUserForm.setFieldsValue({ clientGroupId: groupId });
  }

  async function handleAddClientUser() {
    try {
      const values = await createUserForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticCreatingMessage" />
        );
        try {
          await clientUsersClient.create(values);
          messageApi.success(
            <FormattedMessage id="StaticCreateSuccessMessage" />
          );
          setCreateModalVisible(false);
          createUserForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelAddClientUser() {
    setCreateModalVisible(false);
    createUserForm.resetFields();
  }

  function OpenModalEditClientUser(record: ClientUserBriefDto) {
    setEditModalVisible(true);
    editUserForm.setFieldsValue(record);
  }

  async function handleEditClientUser() {
    try {
      const values = await editUserForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientUsersClient.forceUpdate(values);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          setEditModalVisible(false);
          editUserForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelEditClientUser() {
    setEditModalVisible(false);
    editUserForm.resetFields();
  }

  function OpenModalResetPassword(record: ClientUserBriefDto) {
    setResetPasswordModalVisible(true);
    setResetPasswordUser(record);
  }

  const handleResetPassword = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );
    setResetPasswordModalVisible(false);

    try {
      if (data.type === 1)
        await clientUsersClient.sendResetPasswordEmail(
          new SendResetPasswordEmailCommand({
            userId: resetPasswordUser?.id,
            defaultLanguageId: data.defaultLanguageId,
          })
        );
      else
        await clientUsersClient.forceChangePassword(
          new ForceUpdateClientUserPasswordCommand({
            userId: resetPasswordUser?.id,
            newPassword: data.newPassword,
          })
        );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  const handleCancelResetPassword = () => {
    setResetPasswordModalVisible(false);
    setResetPasswordUser(undefined);
  };

  function handleResendEmail(id: string) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmResendMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticSendingMessage" />
        );
        try {
          await clientUsersClient.sendNotification(id);
          messageApi.success(
            <FormattedMessage id="StaticSendSuccessMessage" />
          );
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  
  async function handleArchiveUser(id: string) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmArchiveUser" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientUsersClient.archiveClientUser(id);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    // {
    //   title: "Client Name",
    //   dataIndex: "clientName",
    //   key: "clientName",
    //   sorter: true,
    // },
    // {
    //   title: "Client group",
    //   dataIndex: ["clientGroup", "name"],
    //   key: "client_group",
    // },
    {
      // title: "Login ID",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderLoginId" />,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
    },
    {
      // title: "Full Name",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderFullName" />,
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
    },
    {
      // title: "Language",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderLanguage" />,
      dataIndex: "preferredLanguageDisplayName",
      key: "preferredLanguageDisplayName",
      sorter: true,
    },
    {
      // title: "Email",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderEmail" />,
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      // title: "Time Added",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (text: any, record: ClientUserBriefDto) =>
        record.created?.toLocaleString(),
    },
    {
      // title: "Time Modified",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderTimeModified" />,
      dataIndex: "lastModified",
      key: "lastModified",
      sorter: true,
      defaultSortOrder,
      render: (text: any, record: ClientUserBriefDto) =>
        record.lastModified?.toLocaleString(),
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupClientUserTableHeaderAction" />,
      render: (text: any, record: ClientUserBriefDto) => (
        <Space>
          {auth.can(ACTION.Edit, "ClientUsers") ? (
            <Button
              type="primary"
              onClick={() => OpenModalEditClientUser(record)}
            >
              <Space>
                <FormattedMessage id="ClientUserClientUserTableEditButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "ResetPassword") ? (
            <Button
              type="primary"
              onClick={() => OpenModalResetPassword(record)}
            >
              <Space>
                <FormattedMessage id="StaticResetPasswordButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "RegistrationEmail") ? (
            <Button
              type="primary"
              onClick={() => handleResendEmail(record.id as string)}
            >
              <Space>
                <FormattedMessage id="ClientUserClientUserTableSendRegEmailButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Archive, "ClientUsers") ? (
            <Button
              type="primary"
              onClick={() => handleArchiveUser(record.id as string)}
            >
              <Space>
                <FormattedMessage id="ClientUserClientUserTableArchiveButton" />
              </Space>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  const keywordElement = (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={handleKeywordsSearch}
    >
      <Form.Item name="keywords">
        <Input placeholder={StaticKeywords} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="StaticSearchLabel" />
          </Button>
        )}
      </Form.Item>
    </Form>
  );

  return (
    <Layout title="ClientGroupClientUserNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <Header id={groupId}></Header>
          <ClientGroupDetailMenu id={groupId} />
          {mode == Mode.Main && (
            <Space justify="space-between">
              <Space justify="flex-start">{keywordElement}</Space>
              <Space wrap>
                {auth.can(ACTION.Edit, "Migrate") ? (
                  <Button
                    ghost
                    type="primary"
                    shape="round"
                    onClick={() => {
                      setMode(Mode.Migration);
                    }}
                  >
                    <FormattedMessage id="ClientGroupClientUserContentMigrationButton" />{" "}
                    <RightOutlined />
                  </Button>
                ) : null}
                {auth.can(ACTION.Create, "ClientUsers") ? (
                  <Button type="primary" onClick={OpenModalAddClientUser}>
                    <Space>
                      <PlusOutlined />
                      <FormattedMessage id="ClientGroupClientUserContentAddButton" />
                    </Space>
                  </Button>
                ) : null}
                {auth.can(ACTION.Download, "ClientUsers") ? (
                  <Button type="primary" onClick={handleDownloadClientUsers}>
                    <FormattedMessage id="ClientGroupClientUserContentDownloadButton" />
                  </Button>
                ) : null}
                {auth.can(ACTION.Upload, "ClientUsers") ? (
                  <Upload action={handleUploadClientUsers}>
                    <Button type="primary">
                      <FormattedMessage id="ClientGroupClientUserContentUploadButton" />
                    </Button>
                  </Upload>
                ) : null}
              </Space>
            </Space>
          )}
          {mode != Mode.Main && (
            <Space justify="space-between">
              <Space justify="flex-start">
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setMode(Mode.Main);
                  }}
                >
                  <LeftOutlined />
                </Button>
                <FormattedMessage id="ClientGroupClientUserContentMigrationButton" />
              </Space>
              <Space wrap>{keywordElement}</Space>
            </Space>
          )}
          {mode == Mode.Main && (
            <Table
              bordered
              dataSource={[...state.items]}
              columns={columns}
              rowKey={(r) => r.id}
              pagination={state.pagination}
              loading={state.loading}
              onChange={handleTableChange}
            />
          )}
          {mode != Mode.Main && (
            <ClientUserMigrationTable
              keywords={keywords}
              groupId={groupId}
              columns={columns.slice(0, -1)}
              auth={auth}
            />
          )}
        </Space>
      </Card>
      <Modal
        visible={createModalVisible}
        title={<FormattedMessage id="ClientGroupClientUserModalAddTitle" />}
        okText={<FormattedMessage id="StaticCreateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleAddClientUser}
        onCancel={handleCancelAddClientUser}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={createUserForm} {...formItemLayout}>
          <Form.Item name="clientGroupId" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="userName"
            label={
              <FormattedMessage id="ClientUserClientUserStaticLoginIDLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <FormattedMessage id="ClientUserClientUserStaticPasswordLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={
              <FormattedMessage id="ClientUserClientUserStaticFullNameLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="hasEmailNotification"
            label={
              <FormattedMessage id="ClientUserClientUserStaticEmailNotificationLabel" />
            }
            valuePropName="checked"
            initialValue={true}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.hasEmailNotification !==
              currentValues.hasEmailNotification
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="email"
                label={
                  <FormattedMessage id="ClientUserClientUserStaticEmailLabel" />
                }
                rules={[
                  {
                    type: "email",
                    message: (
                      <FormattedMessage id="ClientUserClientUserStaticInvalidEmailRule" />
                    ),
                  },
                  {
                    required: getFieldValue("hasEmailNotification"),
                    message: (
                      <FormattedMessage id="ClientUserClientUserStaticMissingEmailRule" />
                    ),
                  },
                ]}
                initialValue=""
              >
                <Input />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name="preferredLanguageId"
            label={
              <FormattedMessage id="ClientUserClientUserStaticLanguageLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientUserClientUserStaticMissingLanguageRule" />
                ),
              },
            ]}
          >
            <LanguageSelectFilter
              isEnabled={true}
              allowClear={false}
              handleAction={(value) =>
                createUserForm.setFieldsValue({ preferredLanguageId: value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={editModalVisible}
        title={<FormattedMessage id="ClientUserClientUserModalEditTitle" />}
        okText={<FormattedMessage id="StaticUpdateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleEditClientUser}
        onCancel={handleCancelEditClientUser}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={editUserForm} {...formItemLayout}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={
              <FormattedMessage id="ClientUserClientUserStaticFullNameLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientUserClientUserStaticMissingFullNameRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <FormattedMessage id="ClientUserClientUserStaticEmailLabel" />
            }
            rules={[
              {
                type: "email",
                message: (
                  <FormattedMessage id="ClientUserClientUserStaticInvalidEmailRule" />
                ),
              },
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientUserClientUserStaticMissingEmailRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="preferredLanguageId"
            label={
              <FormattedMessage id="ClientUserClientUserStaticLanguageLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientUserClientUserStaticMissingLanguageRule" />
                ),
              },
            ]}
          >
            <LanguageSelectFilter
              isEnabled={true}
              allowClear={false}
              handleAction={(value) =>
                editUserForm.setFieldsValue({ preferredLanguageId: value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <ResetPasswordModal
        visible={resetPasswordModalVisible}
        handleAction={handleResetPassword}
        handleCancelAction={handleCancelResetPassword}
        defaultLanguageId={resetPasswordUser?.preferredLanguageId}
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default GroupLevelClientUserPage;
