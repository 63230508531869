import React, { useEffect } from "react";
import { Form, Modal, Input, Radio, Space } from "antd";
import { FormattedMessage } from "react-intl";
import LanguageSelectFilter from "./LanguageSelectFilter";

interface ModalProps {
  visible: boolean;
  handleAction: (data: any) => Promise<void>;
  handleCancelAction: () => void;
  defaultLanguageId?: string;
}

const modal = ({
  visible,
  handleAction,
  handleCancelAction,
  defaultLanguageId,
}: ModalProps) => {
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const handleOK = async () => {
    try {
      const values = await form.validateFields();

      if (values) {
        handleAction(values);

        form.resetFields();
      }
    } catch (err) {
      /* form error block*/
    }
  };

  const handleCancel = () => {
    form.resetFields();
    handleCancelAction();
  };

  useEffect(() => {
    form.setFieldsValue({ defaultLanguageId });
  }, [defaultLanguageId]);

  return (
    <Modal
      visible={visible}
      title={<FormattedMessage id="StaticResetPasswordButton" />}
      okText={<FormattedMessage id="StaticUpdateButton" />}
      cancelText={<FormattedMessage id="StaticCancelButton" />}
      onOk={handleOK}
      onCancel={handleCancel}
      destroyOnClose={true}
    >
      <Form layout="horizontal" form={form} {...formItemLayout}>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="StaticMissingSelectRule" />,
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>
                <FormattedMessage id="StaticResetPasswordOption1Label" />
              </Radio>
              <Radio value={2}>
                <FormattedMessage id="StaticResetPasswordOption2Label" />
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.type !== currentValues.type
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("type") === 1 ? (
              <Form.Item
                name="defaultLanguageId"
                label={<FormattedMessage id="StaticPreferredLanguageLabel" />}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="StaticMissingPreferredLanguageRule" />
                    ),
                  },
                ]}
              >
                <LanguageSelectFilter
                  isEnabled={true}
                  allowClear={false}
                  handleAction={(value) =>
                    form.setFieldsValue({ defaultLanguageId: value })
                  }
                />
              </Form.Item>
            ) : getFieldValue("type") === 2 ? (
              <>
                <Form.Item
                  name="newPassword"
                  label={<FormattedMessage id="StaticPasswordLabel" />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="StaticMissingPasswordRule" />
                      ),
                    },
                  ]}
                  initialValue=""
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={<FormattedMessage id="StaticConfirmPasswordLabel" />}
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="StaticMissingPasswordRule" />
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  initialValue=""
                >
                  <Input />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default modal;
